import React, { useEffect, useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import numeral from 'numeral';
import { Tooltip } from '@mui/material';

import AMRPredictionsBlock from '../AMRPredictions/AMRPredictionsBlock.jsx';
import resistanceGenesReference from './../AMRPredictions/resistanceGenesReference.js'
import useKeynomeApi from './api.js';
import aggregateKgASTQualityLimitations from './KgASTQualityLimitations.js';

function IdentifiedSpeciesRow(props) {
  const {
    isIllumina,
    identifiedSpecies,
    isDiagnosticView,
    showAST,
    showKIDCalls,
    showRnDModels,
    showImputedCalls,
    showAlignmentMetrics,
    showCoverageBreadth,
    showCoverageDepth,
    showAssemblyMetrics,
    showAssemblyMBsToSpecies,
    showContigsToSpecies,
    showResistanceGenes,
    q20Rate,
    assemblyN50,
    assemblyCoverageLT2xDepth,
    totalMicrobialMegabases,
    setLoadingResistanceGenes,
    setLoadingImputations,
    mapToNonSusceptible,
  } = props;
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { fetchAmrPredictions, fetchImputations, fetchResistanceGenes } = useKeynomeApi();

  const [resistanceGenes, setResistanceGenes] = useState(null);
  const [visibleAmrPredictions, setVisibleAmrPredictions] = useState(null);
  const [amrPredictions, setAmrPredictions] = useState(null);
  const [imputations, setImputations] = useState(null);
  let showKIDColumns = identifiedSpecies.attributes.species_call_raw_value !== null;

    useEffect(() => {
        if (!amrPredictions) {
            return;
        }

      let predictions = Object.values(amrPredictions).filter(
          (item) => {
              let performances = ['H', 'VH']
              if (showRnDModels) {
                performances.push('M')
              }

              return performances.includes(item.attributes.model_performance_label)
          }
      );

      if (showImputedCalls && imputations) {
          predictions = predictions.concat(imputations)
      }

      if (showResistanceGenes && resistanceGenes) {
          const drugsWithPredictions = predictions.reduce(
              (acc, item) => {
                acc.add(item.attributes.model_drug || item.attributes.drug)
                return acc 
              },
              new Set()
          )

          const additionalResGenePredictions = Object.values([...resistanceGenes].reduce(
              (acc, hierarchyNode) => {
                  const nodeDetails = resistanceGenesReference[hierarchyNode]
                  if (!nodeDetails) {
                      return acc
                  }

                  const drugsWithoutPredictions = nodeDetails.antibiotics.filter(
                    item => !drugsWithPredictions.has(item)
                  )

                  if (!drugsWithoutPredictions.length) {
                      return acc
                  }

                  return drugsWithoutPredictions.reduce(
                      (innerAcc, drug) => {
                          if (!innerAcc[drug]) { 
                              innerAcc[drug] = {
                                  drug: drug,
                                  resistanceGenes: []
                              }
                          }

                          innerAcc[drug].resistanceGenes.push(nodeDetails.display_name)
                          return innerAcc
                      },
                      acc
                  )
              }, {}))

          predictions = predictions.concat(
            additionalResGenePredictions
          )
      }

        predictions = predictions.filter(prediction => {
            const drug = prediction.attributes?.model_drug || prediction.attributes?.drug || prediction.drug
            return drug !== 'cefoxitin' && drug !== 'methicillin'
        })

      predictions.sort((a, b) => {
        const aDrug = a.attributes?.model_drug || a.attributes?.drug || a.drug
        const bDrug = b.attributes?.model_drug || b.attributes?.drug || b.drug
        return aDrug.localeCompare(bDrug)
     })

        setVisibleAmrPredictions(predictions)
    }, [amrPredictions, imputations, resistanceGenes, showResistanceGenes, showRnDModels, showImputedCalls])

  const loadResistanceGenes = useCallback(() => {
    setLoadingResistanceGenes((previousValue) => previousValue + 1)
    getAccessTokenSilently()
      .then(accessToken => fetchResistanceGenes(accessToken, identifiedSpecies))
      .then((resistanceGenes) => {
          setLoadingResistanceGenes((previousValue) => previousValue - 1)
          setResistanceGenes(resistanceGenes)
      })
      }, [getAccessTokenSilently, fetchResistanceGenes, identifiedSpecies, setLoadingResistanceGenes]);

  const loadImputations = useCallback((amrPredictions, predictedDrugs) => {
    setLoadingImputations((previousValue) => previousValue + 1);
    return getAccessTokenSilently()  // Return this Promise
      .then(accessToken => fetchImputations(accessToken, amrPredictions, predictedDrugs))
      .then((imputations) => {
        setLoadingImputations((previousValue) => previousValue - 1);
        setImputations(imputations);
      })
      .catch((error) => console.error(error));
  }, [getAccessTokenSilently, fetchImputations, setLoadingImputations]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (!identifiedSpecies || amrPredictions || (!identifiedSpecies.attributes.species_call_positive && !isIllumina)) {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;
    const speciesRank = identifiedSpecies.attributes.species_call_rank_by_kid_value ?? 
                      identifiedSpecies.attributes.species_call_rank_by_reads;

    getAccessTokenSilently()
      .then(accessToken => fetchAmrPredictions(accessToken, identifiedSpecies, signal))
      .then((predictions) => {
        if (!predictions.high_confidence && !predictions.medium_confidence) {
          setAmrPredictions(null);
          return;
        }

        const allAmrPredictions = {
          ...predictions.high_confidence, 
          ...predictions.medium_confidence
        }

        setAmrPredictions(allAmrPredictions);
        if (speciesRank <= 5) {
          return loadImputations(
            predictions.high_confidence, 
            Object.values(allAmrPredictions).map(item => item.attributes.model_drug)
          ).then(() => 
            loadResistanceGenes());
        }
      })
      .catch((error) => console.error(error));

      return () => controller.abort()
  }, [amrPredictions, getAccessTokenSilently, fetchAmrPredictions, loadImputations, identifiedSpecies, isIllumina, isAuthenticated, loadResistanceGenes]);

  let amrPredictionsContent = null;
  if (
      (showAST || isDiagnosticView) 
      && (identifiedSpecies.attributes.species_call_positive || isIllumina) 
      && visibleAmrPredictions 
    ) {
      let qualityLimitations = aggregateKgASTQualityLimitations(isIllumina, identifiedSpecies.attributes, q20Rate, assemblyN50, assemblyCoverageLT2xDepth)
      amrPredictionsContent = <AMRPredictionsBlock 
        isDiagnosticView={isDiagnosticView}
        visibleAmrPredictions={visibleAmrPredictions} 
        qualityLimitations={qualityLimitations} 
        amrPredictionsReference={amrPredictions}
        resistanceGenes={resistanceGenes}
        showResistanceGenes={showResistanceGenes}
        mapToNonSusceptible={mapToNonSusceptible}
      />
  }

  const speciesParts = identifiedSpecies.attributes.species.replace('[', '').split(' ');
  const speciesName = `${speciesParts[0][0]}. ${speciesParts.slice(1).join(' ')}`;
  const speciesNameContent = (identifiedSpecies.attributes.species_call_positive || (isIllumina && identifiedSpecies.attributes.reads_frac_agreeing_megabases >= 0.8))
    ? (<p style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow:'ellipsis'}}><b>{speciesName}</b></p>)
    : (<p style={{ fontWeight: (showKIDCalls || isIllumina) ? null : 'bold', color: (showKIDCalls || isIllumina) ? 'gray' : null, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow:'ellipsis' }}>{speciesName}</p>);

  let positivityContent = null;
  if (!isDiagnosticView && showKIDCalls && showKIDColumns) {
    positivityContent = (
      <Grid item xs={12} sm={1}>
        {identifiedSpecies.attributes.species_call_positive
      ? (<p style={{ color: 'green' }}><b>Positive </b></p>)
      : (
        <p style={{ color: 'gray' }}>
          Subthreshold
        </p>
      )}
      </Grid>
    );
  }


  const detailContentColor = (identifiedSpecies.attributes.species_call_positive || (!showKIDCalls && !isIllumina) || (isIllumina && identifiedSpecies.attributes.reads_frac_agreeing_megabases >= 0.8)) ? null : 'gray';

  let kidValueContent = null;
  if (!isDiagnosticView && identifiedSpecies.attributes.species_call_raw_value !== null) {
    kidValueContent = (
      <Grid item xs={12} sm={2}>
        <p style={{ color: detailContentColor, marginTop: '5px', marginBottom: '5px' }}><small><b>
          {identifiedSpecies.attributes.species_call_raw_value < 0.001 ? '<0.001' : Math.min(identifiedSpecies.attributes.species_call_raw_value, 1.000).toFixed(3)}
            <br /> KID value
        </b></small></p>
      </Grid>
    );
  }

  let readsToSpeciesContent = null;
  if (!isDiagnosticView && !showKIDColumns) {
    readsToSpeciesContent = (
      <Grid item xs={12} sm={2}>
        <p style={{ color: detailContentColor, marginTop: '5px', marginBottom: '5px' }}><small>
          <b>{identifiedSpecies.attributes.reads_to_species ? identifiedSpecies.attributes.reads_to_species.toLocaleString() : '-'} read pairs</b>
            <br />
          matching organism
        </small></p>
      </Grid>
    );
  }

  let fractionMegabasesToGenusContent = null;
  if (!isDiagnosticView && isIllumina && false) {
    fractionMegabasesToGenusContent = (
      <Grid item xs={12} sm={2}>
        <p style={{ color: detailContentColor, marginTop: '5px', marginBottom: '5px' }}><small>
          <b>{identifiedSpecies.attributes.reads_frac_agreeing_megabases ? (identifiedSpecies.attributes.reads_frac_agreeing_megabases * 100).toFixed(2) : '-'}%</b>
            <br />
          matching genus
        </small></p>
      </Grid>
    );
  }

  let megabasesToSpeciesContent = null;
  if (!isDiagnosticView) {
      let megabasesToSpeciesValue = null
      let readsToSpeciesValue = null
      let percentMegabasesToSpeciesValue = null
      if (identifiedSpecies.attributes.reads_megabases_to_species) {
          megabasesToSpeciesValue = (
            identifiedSpecies.attributes.reads_megabases_to_species.toFixed(3)
          )

          percentMegabasesToSpeciesValue = totalMicrobialMegabases ? ((
            identifiedSpecies.attributes.reads_megabases_to_species / totalMicrobialMegabases
          ) * 100).toFixed(2) : null
      }

      if (identifiedSpecies.attributes.reads_to_species) {
        readsToSpeciesValue = identifiedSpecies.attributes.reads_to_species.toLocaleString()
      }

    megabasesToSpeciesContent = (
      <Grid item xs={12} sm={3}>
        <p style={{ color: detailContentColor, marginTop: '5px', marginBottom: '5px' }}><small>
          <b>
            {megabasesToSpeciesValue ?? '-'} MBs {percentMegabasesToSpeciesValue ? `(${percentMegabasesToSpeciesValue}%)` : null} {(readsToSpeciesValue && identifiedSpecies.attributes.species_call_raw_value !== null) ? `/ ${readsToSpeciesValue < 1000 ? readsToSpeciesValue : numeral(readsToSpeciesValue).format('0.0a')} ${isIllumina ? "read pairs" : "reads"}` : null}
        </b>
            <br />
          matching organism
        </small></p>
      </Grid>
    );
  }

  let coverageBreadthContent = null;
  if (!isDiagnosticView && showAlignmentMetrics && showCoverageBreadth && !isIllumina && identifiedSpecies.attributes.species_call_positive) {
    coverageBreadthContent = (
      <Grid item xs={12} sm={2}>
        <p style={{ color: detailContentColor, marginTop: '5px', marginBottom: '5px' }}><small>
          {identifiedSpecies.attributes.perc_nonzero_coverage ? (identifiedSpecies.attributes.perc_nonzero_coverage * 100).toFixed(2) : '-'}
          %<br/>reference coverage</small>
        </p>
      </Grid>
    );
  }

  let coverageDepthContent = null;
  if (!isDiagnosticView && showAlignmentMetrics && showCoverageDepth && !isIllumina && identifiedSpecies.attributes.species_call_positive) {
    coverageDepthContent = (
      <Grid item xs={12} sm={2}>
        <p style={{ color: detailContentColor, marginTop: '5px', marginBottom: '5px' }}><small>
          {identifiedSpecies.attributes.avg_depth ? (identifiedSpecies.attributes.avg_depth).toFixed(2) : '-'}
          <br/>average depth</small>
        </p>
      </Grid>
    );
  }

  let assemblyMegabasesToSpeciesContent = null;
  if (!isDiagnosticView && showAssemblyMetrics && showAssemblyMBsToSpecies && isIllumina) {
    assemblyMegabasesToSpeciesContent = (
      <Grid item xs={12} sm={2}>
        <p style={{ color: detailContentColor, marginTop: '5px', marginBottom: '5px' }}><small>
          {identifiedSpecies.attributes.assembly_megabases_to_species ? (identifiedSpecies.attributes.assembly_megabases_to_species).toLocaleString() : '-'} MBs from raw
          <br/> assembly matching organism</small>
        </p>
      </Grid>
    );
  }

  let assemblyContigsToSpeciesContent = null;
  if (!isDiagnosticView && showAssemblyMetrics && showContigsToSpecies && isIllumina) {
    assemblyContigsToSpeciesContent = (
      <Grid item xs={12} sm={showKIDColumns ? 2 : 3}>
        <p style={{ color: detailContentColor, marginTop: '5px', marginBottom: '5px' }}><small>
          {identifiedSpecies.attributes.contigs_to_species ? (identifiedSpecies.attributes.contigs_to_species).toLocaleString() : '-'} contigs from raw
          <br/>assembly matching organism</small>
        </p>
      </Grid>
    );
  }


  return (
    <Box>
      <Grid
        container
        sx={{
          pl: 1,
          pr: 1,
          textAlign: 'center',
          borderBottom: '#bdbdbd 2px solid'
        }}
      >
        <Grid item xs={12} sm={2} sx={{ textAlign: 'left' }}>
          <Tooltip title={identifiedSpecies.attributes.species} placement="top">
            {speciesNameContent}
          </Tooltip>
        </Grid>
        {positivityContent}
        {kidValueContent}
        {readsToSpeciesContent}
        {megabasesToSpeciesContent}
        {fractionMegabasesToGenusContent}
        {coverageBreadthContent}
        {coverageDepthContent}
        {assemblyMegabasesToSpeciesContent}
        {assemblyContigsToSpeciesContent}
      </Grid>
      {amrPredictionsContent}
    </Box>
  );
}

export default IdentifiedSpeciesRow;
