import React, { useEffect, useState, useMemo } from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Box, Typography, Alert, Tooltip } from '@mui/material';
import { DataGrid, gridClasses, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExportContainer, GridCsvExportMenuItem } from '@mui/x-data-grid';
import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { chunkArray } from './api';
import { identifyOverlappingResistanceGenes } from '../AMRPredictions/resistanceGeneUtils';
import aggregateKgASTQualityLimitations from './KgASTQualityLimitations';
import WarningIcon from '@mui/icons-material/Warning';

const partialCoverageMethods = [ 'PARTIALX', 'PARTIALP', 'PARTIAL_CONTIG_ENDX', 'PARTIAL_CONTIG_ENDP', 'INTERNAL_STOP']
const geneHasPartialCoverage = (gene) => {
  // If overlapping hit and multiple methods are used, we consider it partial coverage if at least
  // one of the methods is a partial coverage method.
  return gene.method.split(', ')
    .some(method => partialCoverageMethods.includes(method))
};

const ResistanceGenesDialog = ({ isIllumina, analysis, sample, identifiedSpecies, open, onClose, setAllAvailableResistanceGenesLoading }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [allAvailableResistanceGenes, setAllAvailableResistanceGenes] = useState(null);
  const [visibleResistanceGenes, setVisibleResistanceGenes] = useState(null);
  const [showKgASTQualityWarning, setShowKgASTQualityWarning] = useState(false);

  const isPolymicrobial = (identifiedSpecies || []).filter(species => species.attributes.species_call_positive).length > 1;
  const filterOutResGenesWithQualityLimitations = isPolymicrobial || isIllumina;

  useEffect(() => {
    const fetchAllAvailableResistanceGenes = async () => {
      if (!identifiedSpecies?.length || !isAuthenticated) return;
      try {
        const accessToken = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${accessToken}` };
  
        const positiveIdentifiedSpecies = identifiedSpecies.filter(species => species.attributes.species_call_positive);
        const speciesChunks = chunkArray(positiveIdentifiedSpecies, 5);
        let allResults = [];

        setAllAvailableResistanceGenesLoading(true);
        for (const chunk of speciesChunks) {
          const results = await Promise.all(
            chunk.map(async ({ id, attributes }) => {
              const response = await fetch(
                `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/identified_species/${id}/resistance_genes`,
                { method: 'GET', headers }
              );
              const data = await response.json();

              const filteredGenes = data?.data.filter(
                gene => gene.attributes?.element_type?.toUpperCase() === 'AMR'
              );

              const uniqueGenes = filteredGenes.reduce((acc, gene) => {
                const { id, attributes } = gene;
                // TODO: determine what the cases are that the same gene symbol would appear twice
                // same gene in multiple locations or multiple contigs? Same gene with different 
                // drug classes?
                // We might aggregate data (for example join with comma) if collisions have data we
                // might want to retain and display.
                acc[attributes.gene_symbol] = {
                  id,
                  contig_id: attributes.contig_id,
                  strand: attributes.strand,
                  start: attributes.start,
                  stop: attributes.stop,
                  gene_symbol: attributes.gene_symbol,
                  drug_class: attributes.drug_class || 'Unknown',
                  method: attributes.method,
                  gene_family: attributes.gene_family
                };
                return acc;
              }, {});

              const genesById = Object.values(uniqueGenes).reduce((acc, gene) => {
                acc[gene.id] = gene;
                return acc;
              }, {});

              const geneClusters = identifyOverlappingResistanceGenes(Object.values(genesById));

              const uniqueGenesWithOverlaps = geneClusters.map(cluster => {
                if (cluster.length === 1) {
                  const gene = genesById[cluster[0]];
                  return {
                    contig_id: gene.contig_id,
                    start: gene.start,
                    stop: gene.stop,
                    gene_symbol: gene.gene_symbol,
                    drug_class: gene.drug_class,
                    method: gene.method
                  };
                }
            
                const geneFamilies = new Set(cluster.map(id => genesById[id].gene_family || genesById[id].gene_symbol));
                const uniqueDrugClasses = new Set(cluster.flatMap(id => genesById[id].drug_class.split('/')));
                const uniqueMethods = new Set(cluster.map(id => genesById[id].method))

                return {
                  gene_symbol: geneFamilies.size > 1 ? `Gene similar to ${Array.from(geneFamilies).join(' or ')}` : geneFamilies[0],
                  drug_class: Array.from(uniqueDrugClasses).join('/'),
                  method: Array.from(uniqueMethods).join(', '), 
                };
              });

              return { species_name: attributes.species, genes: uniqueGenesWithOverlaps };
            })
          );

          allResults = [...allResults, ...results];
        }

        const resistanceGenes = allResults.reduce((acc, { species_name, genes }) => {
          if (genes.length) acc[species_name] = genes;
          return acc;
        }, {});
        setAllAvailableResistanceGenesLoading(false);
        setAllAvailableResistanceGenes(resistanceGenes);
      } catch (error) {
        datadogRum.addError(error);
        console.error('Error fetching resistance genes:', error);
      }
    };

    fetchAllAvailableResistanceGenes();
  }, [identifiedSpecies, getAccessTokenSilently, isAuthenticated, setAllAvailableResistanceGenesLoading]);

  useEffect(() => {
    if (!allAvailableResistanceGenes) return;

    const speciesFailingKgASTQc = identifiedSpecies
      .filter(species => species.attributes.species_call_positive && aggregateKgASTQualityLimitations(
          isIllumina,
          species.attributes,
          analysis?.attributes.q20_rate,  
          analysis?.attributes.assembly_n50,
          analysis?.attributes.assembly_ratio_lt_2x_coverage
        ).length > 0
      ).map(species => species.attributes.species);
    const speciesWithResistanceGenesFailingQc = new Set(
      Object.keys(allAvailableResistanceGenes)
      .filter(species => speciesFailingKgASTQc.includes(species))
    );
    
    if (speciesWithResistanceGenesFailingQc.size > 0) setShowKgASTQualityWarning(true);

    setVisibleResistanceGenes(
      !filterOutResGenesWithQualityLimitations ? allAvailableResistanceGenes :
      Object.entries(allAvailableResistanceGenes)
      .reduce((acc, [speciesName, genes]) => {
        if (!speciesWithResistanceGenesFailingQc.has(speciesName)) acc[speciesName] = genes;
        return acc;
      }, {})
    )

  }, [allAvailableResistanceGenes, identifiedSpecies, isIllumina, analysis, filterOutResGenesWithQualityLimitations, setVisibleResistanceGenes, setShowKgASTQualityWarning]);

  const columns = useMemo(() => [
    { field: 'organism',
      headerName: 'Organism',
      flex: 1 ,
      renderCell: (params) => {
        return <span>
          {params.value}
          {params.row.unclassifiedDuplicateMarker && <b style={{ fontSize: '15px', fontStyle: 'bold', color: 'orange' }}>*</b>}
        </span>
      }
    },
    { field: 'drugClass', headerName: 'Drug Class', flex: 1 },
    { 
      field: 'gene', 
      headerName: 'Resistance Marker', 
      flex: 1, 
      renderCell: (params) => <b style={{ fontStyle: 'italic' }}>{params.value}</b>
    },
    {
      field: 'coverage',
      headerName: 'Gene Coverage',
      flex: 1
    }
  ], []);

  const rows = useMemo(() => {
    if (!visibleResistanceGenes) return [];

    // Identify identical resistance markers (same contig, gene symbol, start, and stop) to flag markers from
    // unclassified contigs (reported in multiple species)
    const identicalMarkersMap = new Map();
    Object.entries(allAvailableResistanceGenes).forEach(([speciesName, genes]) => {
      genes.forEach(gene => {
        if (gene?.contig_id && gene.gene_symbol && gene?.start && gene?.stop) {
          const key = `${gene.contig_id}-${gene.gene_symbol}-${gene.start}-${gene.stop}`;
          identicalMarkersMap.set(key, (identicalMarkersMap.get(key) || 0) + 1);
        }
      });
    });

    return Object.entries(visibleResistanceGenes || {})
    .sort(([speciesNameA], [speciesNameB]) => speciesNameA.localeCompare(speciesNameB))
    .flatMap(
      ([speciesName, genes]) =>
        genes
        .sort((a, b) => {
          const drugClassComparison = a.drug_class.localeCompare(b.drug_class);
          return drugClassComparison !== 0 ? drugClassComparison : a.gene_symbol.localeCompare(b.gene_symbol);
        })
        .map((gene, index) => {
          const key = `${gene?.contig_id}-${gene.gene_symbol}-${gene?.start}-${gene?.stop}`;
          return {
            id: `${speciesName}-${index}`,
            organism: speciesName,
            gene: gene.gene_symbol,
            drugClass: gene.drug_class,
            coverage: geneHasPartialCoverage(gene) ? 'Partial' : 'High',
            unclassifiedDuplicateMarker: (identicalMarkersMap.get(key) || 0) > 1
          }
        })
    );
  }, [allAvailableResistanceGenes, visibleResistanceGenes]);

  function GridCustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExportContainer>
          <GridCsvExportMenuItem options={{ fileName: sample.attributes.sample_name + '_resistance_markers' }}/>
        </GridToolbarExportContainer>
      </GridToolbarContainer>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" scroll="paper" >
      <DialogTitle sx={{ backgroundColor: "#eee" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h7">Complete Resistance Marker Profile</Typography>
          <Tooltip 
                placement="left-start"
                title={
                  <Typography fontSize={12}>
                    Resistance marker presence or absence is often not indicative of antibiotic resistance or susceptibility.
                    Refer to resistance genes reported alongside AMR predictions for highest predictive value.
                  </Typography>
                }
                arrow
            >
                <WarningIcon fontSize="medium" color='primary' sx={{ paddingLeft: 1, cursor: 'pointer' }} />
            </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ padding: 2, '& .even': { backgroundColor: '#fff' }, '& .odd': { backgroundColor: '#f5f5f5' } }}>
        {rows.length > 0 ? (
          <>
            <Typography paragraph sx={{ alignContent: 'center', marginBottom: 0 }}>
              The following table represents a complete profile of resistance markers identified in the analyzed sample.
            </Typography>
            {isPolymicrobial &&
              <Typography variant='body2' color='gray' sx={{fontStyle: 'italic'}}>
                Comprehensive resistance marker profiling for highly mixed samples is still under development.
                Please notify our team at support@dayzerodiagnostics.com of any confusing or inconsistent results.
              </Typography>
            }
            {showKgASTQualityWarning && 
              <Alert severity="warning" sx={{ marginTop: 2 }}>
                {filterOutResGenesWithQualityLimitations ? "Some identified species do not have resistance markers displayed " : "Resistance markers are flagged high risk "}
                due to insufficient sequencing data or assembly quality.
                Caution recommended in interpreting resistance marker profiles.
              </Alert>
            }
            
            <DataGrid
              rows={rows}
              columns={columns}
              disableColumnSorting
              getRowHeight={() => 'auto'}
              getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
              slots={{ toolbar: GridCustomToolbar }}
              sx={{ marginTop: 2, maxHeight: '400px', [`& .${gridClasses.cell}`]: { py: 0.75 } }}
            />
            {rows.some(row => row.unclassifiedDuplicateMarker) && (
              <Typography variant='body2' color='gray' sx={{fontStyle: 'italic', marginTop: 1}}>
                {<b style={{ fontSize: '15px', fontStyle: 'bold', color: 'orange' }}>*</b>} Resistance marker cannot be definitively assigned to specified organism.
              </Typography>
            )}
          </>
          ) : (showKgASTQualityWarning ? <p>Resistance markers not displayed due to insufficient sequencing data or assembly quality.</p> : <p>No resistance markers found.</p>)}
        </Box>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#eee" }}>
        <Button color="primary" sx={{ marginRight: 2 }} onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResistanceGenesDialog;
