import React, { useState } from 'react';
import { Box, Alert, Button } from '@mui/material';
import AMRPredictionRow from './AMRPredictionRow';

const styles = {
    warningBox: {
        textAlign: 'left',
        backgroundColor: '#eee'
    },
    warningAlert: {
        borderRadius: 0
    },
    showButton: {
        backgroundColor: 'white',
        color: 'warning.dark',
        margin: '4px',
        '&:hover': {
            backgroundColor: '#f5f5f5',
        }
    },
    predictionsBox: {
        pl: 10,
        textAlign: 'center',
        backgroundColor: '#eee'
    }
};

function AMRPredictionsBlock(props) {
    const {
        isDiagnosticView,
        visibleAmrPredictions,
        qualityLimitations,
        amrPredictionsReference,
        resistanceGenes,
        showResistanceGenes,
        mapToNonSusceptible,
    } = props;

    const [amrPredictionsExpanded, setAmrPredictionsExpanded] = useState(!qualityLimitations.length);

    return (
        <>
            {qualityLimitations.length ? (
                <Box sx={styles.warningBox}>
                    <Alert 
                        variant="filled" 
                        severity="warning"
                        sx={styles.warningAlert}
                        action={
                            !amrPredictionsExpanded &&
                            <Button 
                                variant="contained"
                                size="small" 
                                sx={styles.showButton}
                                onClick={() => setAmrPredictionsExpanded(true)}
                            >
                                SHOW ANYWAY
                            </Button>
                        }
                    >
                        <b>AMR predictions are {amrPredictionsExpanded ? 'flagged as high risk for error' : 'hidden'} due to quality limitations:</b> {qualityLimitations.join(', ')}
                    </Alert>
                </Box>
            ) : null}
            <Box sx={styles.predictionsBox}>
                {amrPredictionsExpanded && visibleAmrPredictions.map(prediction => (
                    <AMRPredictionRow
                        key={prediction.id}
                        prediction={prediction}
                        isDiagnosticView={isDiagnosticView}
                        amrPredictions={amrPredictionsReference}
                        resistanceGenes={resistanceGenes}
                        showResistanceGenes={showResistanceGenes}
                        mapToNonSusceptible={mapToNonSusceptible}
                    />
                ))}
            </Box>
        </>
    );
}

export default AMRPredictionsBlock;
