import PQueue from 'p-queue';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Container from '@mui/material/Container';
import { useGridApiRef  } from '@mui/x-data-grid';
import { datadogRum  } from '@datadog/browser-rum';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import grey from '@mui/material/colors/grey';
import orange from '@mui/material/colors/orange';
import Nav from './Nav';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

import TermsDialog from './User/TermsDialog';
import DocumentationDialog from './User/DocumentationDialog';

const uploadQueue = new PQueue({ concurrency: 3 });

const theme = createTheme({
  palette: {
    primary: orange,
    secondary: grey,
    default: grey
  },
});

/**
 * Retrieves a sequencing run with a provided ID from the Keynome API including
 * the associated status
 *
 * @param {int} runId unique identifier for the sequencing run to retrieve
 * @param {string} accessToken an acess token authenticating access to the Keynome API
 * @param {AbortSignal} abort signal to provide to fetch to cancel request if needed
 * @returns {Promise}
 */
function retrieveSequencingRunWithStatus(runId, accessToken, signal) {
    const headers = new Headers({
        Authorization: `Bearer ${accessToken}`,
    });

    return fetch(
        `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/sequencing_runs/${runId}?extra_fields=status`,
        {
            method: 'GET',
            headers,
            signal,
        },
    ).then(res => res.json())
}

function Layout() {
    const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

    const [activeProject, setActiveProject] = useState(null);
    const [projects, setProjects] = useState(null);
    const [organization, setOrganization] = useState(null);
    const [userSub, setUserSub] = useState(null);
    const [userData, setUserData] = useState(null);
    const [uploadingFiles, setUploadingFiles] = useState({});
    const [runs, setRuns] = useState(null);
    const [supportedSampleTypes, setSupportedSampleTypes] = useState(['isolate']);
    const [reloadRuns, setReloadRuns] = useState(false);
    const [samples, setSamples] = useState(null);
    const [datasetStatusRef, setDatasetStatusRef] = useState({});
    const [loadStatuses, setLoadStatuses] = useState(false);
    const [activeRun, setActiveRun] = useState(null);
    const [visibleRuns, setVisibleRuns] = useState([]);
    const [addSamplesDialogOpen, setAddSamplesDialogOpen] = useState(false);
    const [termsDialogOpen, setTermsDialogOpen] = useState(false);
    const [showDocs, setShowDocs] = useState(false);

    // snackbar state
    const [progressMessage, setProgressMessage] = useState(null);
    const [userErrorMessage, setUserErrorMessage] = useState(null);

    const datasetsGridRef = useGridApiRef()

    const handleBeforeUnload = useCallback((e) => {
      if (Object.keys(uploadingFiles).length > 0) {
        e.preventDefault();
        e.returnValue = "Files are still uploading. If you close this window, file upload will not complete.";
      }
    }, [uploadingFiles]);

    useEffect(() => {
      if (Object.keys(uploadingFiles).length > 0) {
        window.addEventListener('beforeunload', handleBeforeUnload);
      }
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [uploadingFiles, handleBeforeUnload]);

    /**
     * If user has not accepted terms, force display of terms of use
     */
    useEffect(() => {
      if (!userData) {
        return;
      }

      // TODO: remove extra bool
      setTermsDialogOpen(!userData.data.attributes.latest_terms_accepted_at);
    }, [userData]);

    /**
     * Update user data to set terms accepted
     */
    const acceptTerms = useCallback(() => {
      getAccessTokenSilently()
        .then(accessToken => {
          const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          });

          let attributes = {"latest_terms_accepted_at": new Date()}
          return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/users/${userData.data.id}`,
            {
              method: 'PUT',
              headers,
              body: JSON.stringify(
                {
                    data: {
                        type: "user",
                        attributes: attributes
                    }

                }
            )
            },
          )
        })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`Request to ${res.url} failed with ${res.status} (${res.statusText})`)
          }

          return res.json()
        })
        .then((response) => {
          setUserData(response)
        })
        .catch(datadogRum.addError)

    }, [userData, getAccessTokenSilently])

    const handleUserErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
      setUserErrorMessage(null);
    }

    /**
     * Clear active and visible runs (Recently Viewed Datasets) on any change to
     * active project
     */
    useEffect(() => {
        if (!activeProject) {
            return;
        }
        setActiveRun(null)
        setVisibleRuns([])
    }, [activeProject])

    /**
     * On each new activeRun change, add that active dataset to the
     * list of visible runs (Recently Viewed Datasets).
     *
     * Shift the least recently viewed dataset off of the list.
     *
     * A value of 1 is used as a sentinel value to render a blank
     * space if fewer than 3 datasets have been recently viewed
     */
    useEffect(() => {
        if (!activeRun) {
            return
        }

        if (visibleRuns.some(item => item.id === activeRun.id)) {
            return
        }

        let newVisibleRuns = [activeRun]
        if (visibleRuns[0]) {
            newVisibleRuns.push(visibleRuns[0])
        } else {
            newVisibleRuns.push(1)
        }

        if (visibleRuns[1]) {
            newVisibleRuns.push(visibleRuns[1])
        } else {
            newVisibleRuns.push(1)
        }

        setVisibleRuns(newVisibleRuns);
    }, [activeRun, visibleRuns]);

    /**
     * Retrieve the status of a sequencing run (dataset) and update both
     * the reference record mapping sequencing run IDs to statuses and
     * the associated row in the datasets DataGrid reference
     */
    const getStatus = useCallback((runId, accessToken, signal) => {
        return retrieveSequencingRunWithStatus(runId, accessToken, signal)
            .then(response => {
                setDatasetStatusRef(existingRef => {
                    return {
                        ...existingRef,
                        [response.data.id]: response.data.attributes.status
                    }
                })

                datasetsGridRef.current.updateRows(
                    [
                        {
                            id: response.data.id,
                            status: response.data.attributes.status
                        }
                    ]
                )
            })
            .catch((error) => console.error(error));

    }, [datasetsGridRef])

    /**
     * Loads a chunk of sequencing run statuses to populate the datasets GridData data table.
     * 
     * Attempts to only load a sequencing run status if it is currently visible to the
     * user.
     */
    const loadChunkOfStatuses = useCallback(signal => {
        if (!runs) {
            return Promise.resolve()
        }

        let runsToRetrieveStatusesFor = []
        for (let i = 0; i < runs.length; i += 1) {
            if (runsToRetrieveStatusesFor >= 10) {
                break
            }

            const run = runs[i]
            if (datasetStatusRef[run.id] || (!datasetsGridRef.current) || (!datasetsGridRef.current.getRowElement)  || (!datasetsGridRef.current.getRowElement(run.id))) {
                continue
            }

            runsToRetrieveStatusesFor.push(run)
        }

        if (!runsToRetrieveStatusesFor.length) {
            return Promise.resolve()
        }

        return getAccessTokenSilently()
            .then(accessToken => {
                return Promise.all(
                    runsToRetrieveStatusesFor.map(run => getStatus(run.id, accessToken, signal))
                )
            })
    }, [datasetStatusRef, datasetsGridRef, getAccessTokenSilently, getStatus, runs])

    useEffect(() => {
        if (!loadStatuses) {
            return
        }

        const controller = new AbortController();
        const { signal } = controller;

        setTimeout(
            () => {
                loadChunkOfStatuses(signal)
                    .then(() => setLoadStatuses(false))
                    .catch(() => setLoadStatuses(false))
            }, 1000
        )

        return () => controller.abort()
    }, [loadStatuses]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!user || userSub) {
            return
        }

        setUserSub(user.sub)
    }, [user, userSub])

    const triggerAnalysis = useCallback(concatenatedFastqgzData => { // eslint-disable-line react-hooks/exhaustive-deps
        const concatenatedFastqgzID = concatenatedFastqgzData.id
        return getAccessTokenSilently().then(accessToken => {
            const headers = new Headers({
              Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
            });

            // If an R2 sequencing file was uploaded, this is an illumina sequencing
            // file
            const isIllumina = !!concatenatedFastqgzData.attributes.location_r2_signed_upload_url

            let attributes = {
              'remove_human_dna': true
            }

            if (isIllumina) {
                attributes['generate_amr_predictions'] = true
            } else {
                attributes['rapid_turnaround'] = true
                attributes['generate_amr_finder_plus_res_genes'] = true
                attributes['detect_adapters'] = true
            }


          return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/concatenated_fastqgzs/${concatenatedFastqgzID}/analyze`,
            {
              method: 'PUT',
              headers,
                body: JSON.stringify(
                    {
                        data: {
                            type: "analyze",
                            attributes: attributes
                        }

                    }
                )
            },
        )

        })
      .then((res) => {
        if (!res.ok) {
          setUserErrorMessage('Failed to trigger analysis in Keynome Cloud! Please wait a few minutes and try again. Contact support@dayzerodiagnostics.com if you require assistance.');
          throw new Error(`Request to ${res.url} failed with ${res.status} (${res.statusText})`)
        }

        setUploadingFiles((existingUploadingFiles) => {
          const newUploadingFiles = {}
          for (const uploadingConcatenatedFastqgzID in existingUploadingFiles) {
              if (parseInt(uploadingConcatenatedFastqgzID) !== concatenatedFastqgzID) {
                  newUploadingFiles[uploadingConcatenatedFastqgzID] = existingUploadingFiles[uploadingConcatenatedFastqgzID]
              }

          }
            return newUploadingFiles
        });
      })
      .catch(datadogRum.addError);
    }, [getAccessTokenSilently])

    const updateUploadProgress = (r2File = null) => {
      const pending = uploadQueue.size + uploadQueue.pending;
      setProgressMessage({
        status: pending > 0 ? 'inProgress' : 'completed',
        message: pending > 0 
          ? `Uploading sequencing data: ${r2File ? Math.ceil(pending / 2) : pending} samples remaining`
          : `All sequencing files successfully uploaded`
      });
    };
  
    const uploadFile = useCallback((concatenatedFastqgzData, file, r2File) => {
        const newUploadingFiles = {}
        setUploadingFiles((existingUploadingFiles) => {
            for (const [existingConcatenatedFastqgzID, existingConcatenatedFastqgzData] of Object.entries(existingUploadingFiles)) {
                newUploadingFiles[existingConcatenatedFastqgzID] = existingConcatenatedFastqgzData
            }

            newUploadingFiles[concatenatedFastqgzData.id] = concatenatedFastqgzData
            return newUploadingFiles
        });

        setProgressMessage({
          status: 'inProgress',
          message: `Uploading sequencing data: ${Object.keys(newUploadingFiles).length} samples remaining`
        });

        const uploadWithRetry = async (uploadTask, retries = 3, delay = 1000) => {
          let attempt = 0;
          let error;
  
          while (attempt < retries) {
              try {
                  return await uploadTask();
              } catch (err) {
                  error = err;
                  attempt++;
  
                  if (attempt < retries) {
                      await new Promise(resolve => setTimeout(resolve, delay));
                  }
              }
          }
  
          throw new Error(`Upload failed after ${retries} retries: ${error.message}`);
      };

        const uploadR1 = () => fetch(
          concatenatedFastqgzData.attributes.location_signed_upload_url, 
          { method: 'PUT', body: file }
        ).finally(r2File ? null : updateUploadProgress);
      
        const uploadR2 = r2File ? () => fetch(
          concatenatedFastqgzData.attributes.location_r2_signed_upload_url, 
          { method: 'PUT', body: r2File }
        ).finally(() => updateUploadProgress(r2File)) : null;
      
        const uploads = [uploadQueue.add(() => uploadWithRetry(uploadR1))];
        if (uploadR2) uploads.push(uploadQueue.add(() => uploadWithRetry(uploadR2)));      

        return Promise.all(uploads).then((resArray) => {
            if (!resArray.every(res => res.ok)) throw new Error(resArray);
              return triggerAnalysis(concatenatedFastqgzData)
          })
          .catch(
              (error) => {
                setUserErrorMessage("Failed file upload to Keynome Cloud! Please wait a few minutes and try again. Contact support@dayzerodiagnostics.com if you require assistance.")
                console.error(error)
              }
            )
            .finally(() => {
              updateUploadProgress(r2File); // Ensure message updates after upload completion
          
              if (uploadQueue.size === 0 && uploadQueue.pending === 0) {
                uploadQueue.off('active', updateUploadProgress);
                uploadQueue.off('idle', updateUploadProgress);
              }
          });
    }, [triggerAnalysis]);


  const handleActiveProjectChange = (event) => {
    const newActiveProject = projects.find((project) => project.id === event.target.value);
    setActiveProject(newActiveProject);
    setReloadRuns(true)
  };

  useEffect(() => {
    if (!userSub) {
      return;
    }

    getAccessTokenSilently()
    .then(accessToken => {
      const headers = new Headers({
        Authorization: `Bearer ${accessToken}`,
      });

      return fetch(
        `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/users/${userSub}`,
        {
          method: 'GET',
          headers,
        },
      );
    })
    .then((res) => res.json())
    .then((userData) => {
        setUserData(userData)
        datadogRum.setUser({
            id: userData.data.id,
            name: userData.data.attributes.name
        })


    })
    .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, userSub])

  useEffect(() => {
    if (!activeProject) {
      return;
    }

    if (reloadRuns) {
        setRuns(null);
        setReloadRuns(false);
        return
    }

    getAccessTokenSilently()
      .then(accessToken => {
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });

        return fetch(
          `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/projects/${activeProject.id}/sequencing_runs`,
          {
            method: 'GET',
            headers,
          },
        )
      })
      .then((res) => res.json())
      .then((response) => {
        const allRuns = response.data.sort((a,b) => {
            return b.id - a.id
        })

        setRuns(allRuns);
        setLoadStatuses(true);
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, activeProject, reloadRuns, setReloadRuns]);

  useEffect(() => {
    if (!userData) {
      return
    }
    getAccessTokenSilently()
      .then(accessToken => {
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });

        return fetch(
          `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/organizations/${userData.data.attributes.organization_id}`,
          {
            method: 'GET',
            headers,
          },
        )
      })
      .then((res) => res.json())
      .then((response) => {
        setOrganization(response.data)
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, userData])

  const fetchProjects = async (headers, organizationId) => {
    const res = await fetch(
      `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/organizations/${organizationId}/projects`,
      { method: 'GET', headers }
    );
  
    if (!res.ok) {
      throw new Error(`Request to ${res.url} failed with ${res.status} (${res.statusText})`) 
    }
  
    return await res.json();
  };

  useEffect(() => {
    if (!userData) {
      return
    }
    const { organization_id } = userData.data.attributes;

    getAccessTokenSilently()
    .then(accessToken => {
      const headers = new Headers({
        Authorization: `Bearer ${accessToken}`,
      });
      return fetchProjects(headers, organization_id);
    })
    .then((response) => {
      setActiveProject(response.data[0]);
      setProjects(response.data);
    })
    .catch((error) => console.error(error));
  }, [userData, getAccessTokenSilently]);

  useEffect(() => {
    if (!userData) {
      return
    }
    getAccessTokenSilently()
    .then(accessToken => {
      const headers = new Headers({Authorization: `Bearer ${accessToken}`});
      return fetch(
        `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/sample_types`,
        {
          method: 'GET',
          headers,
        },
      )
    })
    .then((res) => res.json())
    .then((response) => {
      setSupportedSampleTypes(Array.from(response.data.map((sampleType) => sampleType.attributes.name)))
    })
    .catch((error) => console.error(error));
  }, [userData, getAccessTokenSilently]);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;

        }
        setProgressMessage(null)
    }

  let snackbarContent = null
  if (!!progressMessage && progressMessage.status === 'completed') {
      snackbarContent = (
          <Alert
          severity="success"
          variant="filled"
          sx={{ width: '100%'  }}
          >
            {progressMessage.message}
          </Alert>
      )
  } else if (!!progressMessage) {
      snackbarContent = (
        <SnackbarContent 
            message={progressMessage.message}
            action={
                (
              <CircularProgress
                    sx={{marginRight: "10px"}}
                    size={15}
                  />
                )
            }
        />
      )
  }

  const datasetsGridRows = useMemo(() => {
      if (!runs) {
          return null
      }

        return runs.map(run => {
            return {
                id: run.id,
                name: run.attributes.name,
                uuid: run.attributes.uuid,
                platform: run.attributes.sequencing_platform || run.attributes.platform,
                status: run.attributes.status,
                sequencer_name: run.attributes.sequencer_name,
                sequencer_sn: run.attributes.minion_sn,
                flowcell: run.attributes.flowcell_name,
                barcode_kit: run.attributes.barcoding_kit,
                library_prep_kit: run.attributes.library_prep_kit,
                created_at: new Date(run.attributes.created_at)
            }
        })

    }, [runs])

    
    const reloadProjects = useCallback(async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });
        const data = await fetchProjects(headers, userData.data.attributes.organization_id);
        setProjects(data.data);
        return data.data;
      } catch (error) {
        datadogRum.addError(error);
      }
    }, [getAccessTokenSilently, userData]);

  return (
    <ThemeProvider theme={theme}>
      <Nav
        projects={projects}
        organization={organization}
        activeProject={activeProject}
        reloadProjects={reloadProjects}
        setActiveProject={setActiveProject}
        onActiveProjectChange={handleActiveProjectChange}
        userData={userData}
        samples={samples}
        setSamples={setSamples}
        datasetsGridRef={datasetsGridRef}
        setShowDocs={setShowDocs}
        setAddSamplesDialogOpen={setAddSamplesDialogOpen}
      />
      <Container maxWidth={false} disableGutters sx={{ overflowX: 'hidden' }}>
        <Outlet context={
            {
                organization,
                userData,
                activeProject,
                supportedSampleTypes,
                uploadFile,
                uploadingFilesCt: Object.keys(uploadingFiles).length,
                datasets: runs,
                reloadRuns,
                setReloadDatasets: setReloadRuns,
                samples,
                setSamples,
                datasetsGridRef,
                datasetsGridRows,
                datasetStatusRef,
                setLoadStatuses,
                recentlyViewedDatasets: visibleRuns,
                activeDataset: activeRun,
                setActiveDataset: setActiveRun,
                addSamplesDialogOpen,
                setAddSamplesDialogOpen,
                setUserData,
                openTermsDialog: () => setTermsDialogOpen(true),
                visibleRuns,
                setVisibleRuns,
                setUserErrorMessage,
                setProgressMessage,
                hasAccessReportViruses: organization?.attributes?.metadata?.FF_report_viruses,
            }
        } />
        <Snackbar 
          open={!!userErrorMessage}
          autoHideDuration={8000}
          onClose={handleUserErrorClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert 
            onClose={handleUserErrorClose}
            severity="error"
            variant='filled'
            sx={{ width: '100%' }}
          >
            {userErrorMessage}
          </Alert>
        </Snackbar>
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={(!!progressMessage && progressMessage.status === 'inProgress') ? null : 5000}      
            open={!!progressMessage}
            onClose={handleSnackbarClose}
        >
          {snackbarContent}
        </Snackbar>
        <TermsDialog isOpen={termsDialogOpen} acceptTerms={acceptTerms} latestTermsAcceptedAt={userData?.data.attributes.latest_terms_accepted_at} setTermsDialogOpen={setTermsDialogOpen} />
        <DocumentationDialog 
        isOpen={showDocs}
        onClose={() => setShowDocs(false)}
      />
      </Container>
    </ThemeProvider>
  );
}

export default Layout;
