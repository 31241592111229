import React from 'react';
import { Box, Typography, Alert } from '@mui/material';


const styles = {
  instructionsContainer: {
    padding: "10px 20px 20px"
  },
  instructionsTitle: {
    fontWeight: "bold",
    mb: 1
  },
  codeBlock: {
    backgroundColor: "#eee",
    padding: "2px 6px",
    borderRadius: "5px"
  },
  listItem: {
    marginBottom: "8px"
  },
  orderedList: {
    margin: 0,
    paddingLeft: "20px"
  }
};

function KeynomeManagerUpload() {
  const isSingleTenant = !process.env.REACT_APP_KEYNOME_AUTH0_DOMAIN.startsWith("dayzerodiagnostics"); 

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Uploading Sequencing Data - Keynome Manager
      </Typography>
      
      <Typography paragraph>
        If you need to upload a large number of files to a dataset, you can utilize the Keynome Manager CLI tool to perform a bulk upload of sequencing data. This can be a lot faster that manually uploading them through the web interface.
      </Typography>

      <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
        Prerequisites
      </Typography>
      <Typography component="div" paragraph>
        <ul>
          <li>Created sequencing dataset</li>
          <li>FASTQ files ready for upload</li>
          <li>Keynome Manager installed</li>
          <ul>
            <li>Click on your profile in the upper right corner and navigate to "Profile > Download Keynome Manager". Here you can find download and installation instructions for your platform.</li>
          </ul>
        </ul>
      </Typography>
      <Alert 
        severity="warning" 
        variant="outlined"
        sx={{ 
          mt: -1,
          mb: 2,
          bgcolor: 'background.paper'
        }}
      >
        FASTQ files uploaded via Keynome Manager must be Gzip compressed with a .fastq.gz extension
      </Alert>
      <Alert 
        severity="warning" 
        variant="outlined"
        sx={{ 
          mt: -1,
          mb: 2,
          bgcolor: 'background.paper'
        }}
      >
        File names are used identify uploaded samples. Avoid using PHI/PII in file names to prevent accidental exposure
      </Alert>

      <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
       Configuring Keynome Manager 
      </Typography>
      <Typography component="div" paragraph>
        <ol>
          <li>Navigate to "Profile > Keynome Manager Clients" and add a new client.</li>
          <li>When provided your new API key, copy it and make it available to your environment in the <code style={styles.codeBlock}>KEYNOME_CLOUD_API_KEY</code> variable where you plan to run the Keynome Manager tool. Once you close this dialog, you will not be able to access the key again.</li>
          <li>Copy the client ID for your new client.</li>
          <li>In a new terminal, run <code style={styles.codeBlock}>keynome-manager configure</code></li>
          <li>Select your region when prompted. {isSingleTenant && "Since you are a single-tenant user, make sure to select the \"I'm using a single-tenant Keynome Cloud deployment\" option instead of a region."}</li>
          {isSingleTenant && <li>Enter these API and auth domains when prompted.</li>}
          {
            isSingleTenant && <ul>
              <li>API domain: <code style={styles.codeBlock}>{process.env.REACT_APP_KEYNOME_API_URL_BASE.replace("https://", "")}</code></li>
              <li>Auth domain: <code style={styles.codeBlock}>{process.env.REACT_APP_KEYNOME_AUTH0_DOMAIN}</code></li>
            </ul>

          }
          <li>Enter the client ID you copied from a previous step when prompted.</li>
          <li>Agree to the prompt for storing the configuration file.</li>
        </ol>
      </Typography>
      <Alert 
        severity="warning" 
        variant="outlined"
        sx={{ 
          mt: -1,
          mb: 2,
          bgcolor: 'background.paper'
        }}
      >
        Be careful not to lose your <code style={styles.codeBlock}>KEYNOME_CLOUD_API_KEY</code>, you will need to repeat all the configuration steps if you do. Also be careful not to accidentally leak these keys (e.g. by commiting them through Git) as they would allow someone else to upload data on your behalf to the platform.
      </Alert>

      <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
        Uploading ONT Sequencing Files
      </Typography>
      <Typography component="div" paragraph>
        <ol>
          <li>Click on your target dataset in the Sequencing Datasets list</li>
          <li>Copy the Upload ID (in the upper right corner) for the dataset</li>
          <li>In a new terminal, run the following command to initiate the upload, where <code style={styles.codeBlock}>&#123;dataset-id&#125;</code> is the ID you copied in the previous step, and <code style={styles.codeBlock}>&#123;upload-directory&#125;</code> is the directory containing all the files to upload.</li>
          <ul>
            <li>
              <code style={styles.codeBlock}>keynome-manager upload &#123;dataset-id&#125; &#123;upload-directory&#125;</code>
            </li>
          </ul>
          <li>As the upload of each file is completed, you can view them in the dataset through the web interface.</li>
        </ol>
      </Typography>

      <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
        Uploading Illumina Sequencing Files
      </Typography>
      <Typography component="div" paragraph>
        <ol>
          <li>Click on your target dataset in the Sequencing Datasets list</li>
          <li>Copy the Upload ID (in the upper right corner) for the dataset</li>
          <li>In a new terminal, run the following command to initiate the upload, where <code style={styles.codeBlock}>&#123;dataset-id&#125;</code> is the ID you copied in the previous step, and <code style={styles.codeBlock}>&#123;upload-directory&#125;</code> is the directory containing all the files to upload.</li>
          <ul>
            <li>
              <code style={styles.codeBlock}>keynome-manager upload &#123;dataset-id&#125; &#123;upload-directory&#125;</code>
            </li>
          </ul>
          <li>As the upload of each file is completed, you can view them in the dataset through the web interface.</li>
        </ol>
      </Typography>
      <Alert 
        severity="warning" 
        variant="outlined"
        sx={{ 
          mt: -1,
          mb: 2,
          bgcolor: 'background.paper'
        }}
      >
        Keynome Cloud will automatically detect and pair R1 and R2 files based on the file names. Both the R1 and R2 files must be staged for upload to proceed. Paired end FASTQ files must be identifiable via _R1/_R2 or _1/_2 file name endings prior to the .fastq.gz extension.
      </Alert>

      <Box sx={{ bgcolor: 'grey.100', p: 2, borderRadius: 1, mt: 2 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
          Notes
        </Typography>
        <Typography variant="body2">
          Sample names are inferred from the FASTQ file names. Each sample in a sequencing dataset must have a unique name - upload will be prevented if a duplicate sample name is detected.
        </Typography>
      </Box>
      <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
        Using Docker to run Keynome Manager 
      </Typography>
      <Typography component="div" paragraph>
        If you find that you are running into compatibility issues with Keynome Manager on your machine, you can run Keynome Manager from within a Docker container. First, make sure you have docker installed on your machine. You can follow the <a href="https://docs.docker.com/engine/install/">official install instructions</a> to do so.
        <ol>
          <li>In the "Profile" page, create a client if you don't already have one, and save the Client ID. Make the API key available on your machine with the <code style={styles.codeBlock}>KEYNOME_CLOUD_API_KEY</code> environment variable.</li>
          <li>Download the latest Keynome Manager binary for Linux from the "Profile" page.</li>
          <li>Navigate to the directory containing the files you want to upload (they can be in a subdirectory) and copy the Keynome Manager binary to this location.</li>
          <li>Run the following command to launch the docker container.</li>
          <Box sx={{ bgcolor: 'grey.100', p: 0.1, borderRadius: 1, m: 2 }}>
            <pre style={{paddingLeft: 20}}>
              <code>{`docker run -it \\
  -v ./:/app \\
  -v ~/.keynome:/root/.keynome \\
  -e KEYNOME_CLOUD_API_KEY=$${'{'}KEYNOME_CLOUD_API_KEY${'}'} \\
  ubuntu:20.04`}
              </code>
            </pre>
          </Box>
          <li>In the container, run <code style={styles.codeBlock}>apt update && apt install -y libssl-dev</code></li> to install required libraries.
          <li>Navigate to <code style={styles.codeBlock}>/app</code>. Run <code style={styles.codeBlock}>./keynome-manager configure</code></li> and follow the configuration prompts, providing your client ID when prompted.
          <li>You should now be able to follow the upload instructions to upload your data from within the container.</li>
        </ol>
      </Typography>
    </>
  );
}

export default KeynomeManagerUpload;
